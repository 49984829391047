import React, { useEffect, useState } from 'react'
import { Head, Hero, Map } from '@components'

export default () => {
  const [ready, setReady] = useState(false)
  useEffect(() => setReady(true), [])

  const [location, setLocation] = useState<{ lat: number, lng: number }>()
  const props = { location, setLocation }

  return <>
    <Head path="/pontos-de-recarga/" />
    <Hero path="/pontos-de-recarga/" {...props} />
    {ready && <Map {...props} />}
  </>
}